<template>
  <article>
    <!-- Início Principal -->
    <v-toolbar dense flat>
      <v-app-bar-nav-icon>
        <v-icon>fas fa-file-upload</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>Importar Produto</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon @click="fecharPagina">
        <v-icon>fas fa-times</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card outlined class="pa-2">
      <v-row dense class="ma-2 mb-1" align="end">
        <v-col cols="12" md="5">
          <v-file-input hide-details="auto" background-color="#E3E1D9" outlined dense color="#018656"
          label="Selecione o arquivo" persistent-hint v-model="uploadFile" hint="Informe o arquivo CSV."
          :loading="carregando" loader-height="4"></v-file-input>
        </v-col>
      </v-row>

      <v-row dense class="justify-end ma-1">
        <v-card-subtitle class="pb-2">
          <a href="/csv_produtos/produtos.csv" download style="text-decoration: none;">
            <v-icon color="#1976d2" small class="mr-2">fas fa-file-upload</v-icon>
            Modelo de arquivo CSV
          </a>
        </v-card-subtitle>

        <v-spacer></v-spacer>

        <v-btn @click="importar()" color="#018454" style="color: white;">
          <v-icon color="white" class="mr-2" small>fas fa-file-upload</v-icon>
          Importar
        </v-btn>
      </v-row>
    </v-card>

    <transition name="fade">
      <v-card v-if="dataProd.length" outlined class="mt-2 pa-2">
        <!-- <v-card-title v-if="!dataProd.length" class="d-flex justify-center pb-0" style="flex-direction: column;">
          CAMPOS PARA IMPORTAÇÃO DE PRODUTOS

          <v-card-subtitle class="pt-2">SEGUIR AS COLUNAS DO MODELO!!!</v-card-subtitle>
        </v-card-title>

        <v-card-text v-if="!dataProd.length">
          <v-row style="height: calc(100vh - 394px); overflow-y: auto;">
            <pre>
              - CODIGO: Código do Produto
              - TIPO: Alfanumérico
              - OBRIGATÓRIO: SIM
              ---
              - DESCRIÇÃO: Nome do produto
              - Tipo: Alfanumérico
              - OBRIGATÓRIO: SIM
              ---
              - COMPRIMENTO: Comprimento do produto, em METROS.
              - TIPO: Numérico decimal, com até 02 (duas) casas
              - EXEMPLO: 1.23
              - OBRIGATÓRIO: NÃO
              ---
              - LARGURA: Largura do produto, em METROS
              - TIPO: Numérico decimal, com até 02 (duas) casas
              - EXEMPLO: 1.23
              - OBRIGATÓRIO: NÃO
              ---
              - ALTURA: Altura do produto, em METROS
              - TIPO: Numérico decimal, com até 02 (duas) casas
              - EXEMPLO: 1.23
              - OBRIGATÓRIO: NÃO
              ---
              - PESO: Peso do produto, em QUILOS
              - TIPO: Numérico decimal, com até 03 (três) casas
              - EXEMPLO: 1.234
              - OBRIGATÓRIO: NÃO
              ---
              - VOLUME: Quantidade de produtos num mesmo volume para cálculo de frete
              - TIPO: Numérico inteiro
              - EXEMPLO: 1
              - OBRIGATÓRIO: NÃO
              ---
              - LIMITACAO_CUBAGEM: Limitação da cubagem, em QUILOS
              - TIPO: Numérico decimal, com até 06 (seis) casas
              - EXEMPLO: 1.860867
              - OBRIGATÓRIO: NÃO
              ---
              - MARCA: Marca do produto
              - TIPO: Alfanumérico
              - OBRIGATÓRIO: NÃO
              ---
              - GRUPO: Grupo do produto
              - TIPO: Alfanumérico
              - OBRIGATÓRIO: NÃO
              ---
              - TOTAL_AGREGADOS: Quantidade total de produtos na mesma caixa/embalagem.
              - TIPO: Numérico inteiro
              - EXEMPLO: 1
              - OBRIGATÓRIO: NÃO
              ---
              - EMBALAMENTO: Define se o produto entra no cálculo de embalamento
              - TIPO: SIM/NÃO
              - OBRIGATÓRIO: NÃO
              ---
              - DOCA_ID: Número identificador da doca onde queira que o produto seja relacionado
              - TIPO: numérico inteiro
              - OBRIGATÓRIO: NÃO
              ---
              - MODAL: Número identificador do modal onde queira que o produto seja relacionado
              - TIPO: numérico inteiro (1 a 5)
              - OBRIGATÓRIO: NÃO
              ---
              - PRAZO_ADICIONAL: Quantidade de dias para acrescentar ao prazo de faturamento para produção desse produto
              - TIPO: numérico inteiro
              - OBRIGATÓRIO: NÃO
              ---
              - VALOR_ADICIONAL: Valor para acrescentar ao preço final do produto
              - TIPO: numérico decimal, com até 02 (duas) casas
              - EXEMPLO: 1.86
              - OBRIGATÓRIO: NÃO
              ---
              - TIPO_ADICIONAL: A informação do campo adicional representa Valor ou Porcentagem
              - TIPO: Caractere 'V' (valor) || Caractere 'P' (Porcentagem)
              - OBRIGATÓRIO: SIM, caso VALOR_ADICIONAL for incluído
              ---
              - CALCULO_ADICIONAL: A informação do campo adicional calcular no valor do Frete ou no valor do Produto.
              - TIPO: Caractere 'F' (Frete) || Caractere 'P' (Produto)
              - OBRIGATÓRIO: SIM, caso VALOR_ADICIONAL for incluído
              ---
              - USAR_MEDIDA_COTACAO: Verifica se utiliza as dimensões gravadas no produto nos cálculos de cotação
              - TIPO: SIM || NÃO
              - OBRIGATÓRIO: NÃO
              ---
              - CUSTO_FRETE: Valor de frete embutido no valor do produto
              - TIPO: numérico decimal, com até 02 (duas) casas
              - EXEMPLO: 1.86
              - OBRIGATÓRIO: NÃO
              ---
              - PRECO_MADEIRA_MADEIRA: Caso trabalhe com MADEIRA MADEIRA, o valor do produto
              - TIPO: numérico decimal, com até 02 (duas) casas
              - EXEMPLO: 1.86
              - OBRIGATÓRIO: NÃO
              ---
              - PRECO_NETSHOES: Caso trabalhe com NETSHOES, o valor do produto
              - TIPO: numérico decimal, com até 02 (duas) casas
              - EXEMPLO: 1.86
              - OBRIGATÓRIO: NÃO
              ---
              - PRECO_LEROY_MERLIN: Caso trabalhe com LEROY MERLIN, o valor do produto
              - TIPO: numérico decimal, com até 02 (duas) casas
              - EXEMPLO: 1.86
              - OBRIGATÓRIO: NÃO
              ---
              - PRECO_VIA_VAREJO: Caso trabalhe com VIA VAREJO, o valor do produto
              - TIPO: numérico decimal, com até 02 (duas) casas
              - EXEMPLO: 1.86
              - OBRIGATÓRIO: NÃO
              ---
              - PRECO_CONVERTIZE: Caso trabalhe com CONVERTIZE, o valor do produto
              - TIPO: numérico decimal, com até 02 (duas) casas
              - EXEMPLO: 1.86
              - OBRIGATÓRIO: NÃO
              ---
              - PRECO_CARREFOUR: Caso trabalhe com CARREFOUR, o valor do produto
              - TIPO: numérico decimal, com até 02 (duas) casas
              - EXEMPLO: 1.86
              - OBRIGATÓRIO: NÃO
              ---
              - PRECO_CONECTA: Caso trabalhe com CONECTA, o valor do produto
              - TIPO: numérico decimal, com até 02 (duas) casas
              - EXEMPLO: 1.86
              - OBRIGATÓRIO: NÃO
              ---
              - PRECO_RENNER: Caso trabalhe com RENNER, o valor do produto
              - TIPO: numérico decimal, com até 02 (duas) casas
              - EXEMPLO: 1.86
              - OBRIGATÓRIO: NÃO
              ---
              - PRECO_SHOPEE: Caso trabalhe com SHOPEE, o valor do produto
              - TIPO: numérico decimal, com até 02 (duas) casas
              - EXEMPLO: 1.86
              - OBRIGATÓRIO: NÃO
            </pre>
          </v-row>
        </v-card-text> -->
        <v-tabs v-model="tab">
          <v-tab>Novos cadastros</v-tab>
          <v-tab>Editados</v-tab>
          <v-tab>Com erro</v-tab>
        </v-tabs>
        <v-tabs-items class="mt-4" v-model="tab">
          <v-tab-item>
            <v-data-table
              :headers="headers"
              hide-default-footer
              :items="dataProd2.filter((val) => val.tipo === 'insert')"
            >
              <template v-slot:item.msg="{ item }">
                {{ item.msg.match(/Item (.*?) inserido/)[1] }}
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item>
            <v-data-table
              :headers="headers"
              hide-default-footer
              :items="dataProd2.filter((val) => val.tipo === 'update')"
            >
            <template v-slot:item.msg="{ item }">
              {{ item.msg.match(/Item (.*?) alterado/)[1] }}
            </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item>
            <v-data-table
              :headers="headers"
              hide-default-footer
              :items="dataProd.filter((val) => !val.ok)"
            >
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </transition>
  </article>
</template>

<script>
import { fecharPagina } from '../../../functions/fechar_pagina';
import { baseApiUrl, showSuccess, showError } from '@/global';
import axios from "axios";

export default {
  name: "ImportarProdutos",

  data: () => ({
    uploadFile: [],
    carregando: false,
    dataProd: [],
    dataProd2: [],
    tab: null,
    headers: [
      { text: 'Código do Produto', value: 'msg', sortable: false }
    ]
  }),

  methods: {
    fecharPagina,

    async importar(){
      this.carregando = true;
      let url = `${baseApiUrl}/produtos-importar?tipo=0`;
      this.dataProd = []
      this.dataprod2 = []
      const data = new FormData();
      data.append('arquivo', this.uploadFile);

      let config = { header : { 'Content-Type' : 'multipart/form-data' } };

      await axios
        .post(url, data, config)
        .then((res) => {
          if(!res.data.retorno){
            showError("Não foi possível importar o arquivo");  
          }else{
            showSuccess("Importação concluída com sucesso!");
            this.dataProd = [...res.data.dados]
            this.dataProd.forEach((val) => {
              if(Array.isArray(val.msg)) {
                val.msg.forEach((x) => {
                  this.dataProd2.push(x)
                })
              }
            })

          }
        }).catch(() => {
          showError("Não foi possível importar o arquivo");
        }).finally(() => {
          this.carregando = false;
        });
    }
  }
}
</script>
<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active em versões anteriores a 2.1.8 */ {
  opacity: 0;
}
</style>